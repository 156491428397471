import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [Sentry.browserTracingIntegration()],
  enabled: environment.sentry.enabled,
  sampleRate: environment.sentry.sampleRate,
  environment: environment.sentry.environment,
  tracesSampleRate: environment.sentry.tracesSampleRate,
  ignoreErrors: [
    'Non-Error exception captured',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
  ],
});

void platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true,
});
