import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import * as Sentry from '@sentry/angular';

import { HTTP_AUTHENTICATION_INTERCEPTOR_PROVIDERS } from '@supy/authentication';
import {
  HTTP_COMMON_INTERCEPTOR_PROVIDERS,
  reduxDevToolsActionSanitizer,
  reduxDevToolsStateSanitizer,
  UnleashModule,
} from '@supy/common';
import {
  HTTP_NOT_FOUND_INTERCEPTOR_PROVIDERS,
  IntercomModule,
  NotFoundModule,
  SNACKBAR_INTERCEPTOR,
  SplashScreenModule,
  VersionCheckModule,
} from '@supy/components';
import { APP_CONFIG, APP_VERSION } from '@supy/core';
import { CountriesModule } from '@supy/countries';
import { OpenTelemetryModule } from '@supy/opentelemetry';
import { SettingsState } from '@supy/settings';
import { UsersModule } from '@supy/users';

import { environment } from '../environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';

const STARTER_MODULES = [UsersModule, CountriesModule];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    RouterModule,
    SplashScreenModule,
    NotFoundModule,
    OpenTelemetryModule.forRoot(environment.opentelemetry),
    VersionCheckModule,
    IntercomModule.forRoot(),
    NgxsModule.forRoot([SettingsState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsStoragePluginModule.forRoot({
      keys: ['supplierOrders.entities'],
      storage: StorageOption.SessionStorage,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      actionSanitizer: reduxDevToolsActionSanitizer,
      stateSanitizer: reduxDevToolsStateSanitizer,
    }),
    NgxsActionsExecutingModule.forRoot(),
    ...STARTER_MODULES,
    UnleashModule.forRoot({
      appName: environment.unleash.appName,
      clientKey: environment.unleash.clientKey,
      url: environment.unleash.proxyUrl,
    }),
  ],
  providers: [
    ...HTTP_COMMON_INTERCEPTOR_PROVIDERS,
    ...HTTP_NOT_FOUND_INTERCEPTOR_PROVIDERS,
    ...HTTP_AUTHENTICATION_INTERCEPTOR_PROVIDERS,
    SNACKBAR_INTERCEPTOR,
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_VERSION,
      useValue: 'beta',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      deps: [Sentry.TraceService],
      useFactory: () => () => Sentry.TraceService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
