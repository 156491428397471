import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@supy/authentication';
import { NotFoundComponent } from '@supy/components';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth').then(m => m.AuthModule),
  },
  {
    path: 'receive-order',
    loadChildren: () => import('./modules/receive-order').then(m => m.ReceiveOrderModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/main').then(m => m.MainModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: '404',
  },
  { path: '404', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      urlUpdateStrategy: 'deferred',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
